import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
//components
import Reviews from './components/pages/reviews';
import './Assets/css/default.min.css';

class App extends Component{
  render(){
    return (
      <Router>
      <div className="App">
        <Route exact path="/:id/:groupid?" component={Reviews} />
      </div>
      </Router>
    );
  }
}

export default App;
