import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Link from '@material-ui/core/Link';
import StarRatings from 'react-star-ratings';
import ReadMoreReact from 'read-more-react';
const { htmlToText } = require('html-to-text');

class Reviews extends Component{
  constructor(props){
    super(props);
    this.state = {
      items: [],
      isLoaded : false,
    }
  }
  componentDidMount() {
    //const API = "https://staging-api.mdatalogic.com/gmbreview.php?id="; 
    const dealerAPI = "https://api.mdatalogic.com/gmbreview.php?id="; 
    const groupAPI = "https://api.mdatalogic.com/gmbreview.php?gid=";
    //const API = "http://localhost:3000/PHP/GoogleReviewAPI/gmbreview.php?id="; 
    const BID = this.props.match.params.id;
    const groupID = this.props.match.params.groupid;
    var API = dealerAPI + BID;
    var enableGroup = 0;
    if(groupID) {
      API = groupAPI + groupID;
      enableGroup = 1;
    }

    fetch(API)
          .then(res => res.json())
          .then(json => {
            this.setState({
              isLoaded: true,
              items: json,
              groupId : enableGroup,
            })
          })
          .catch(function(){
            console.log("Review API fetch error.");
          });
      }

  render(){
    
    var { isLoaded, items, groupId } = this.state;
    var isGroup = false;
    if(groupId ===1) {
      isGroup = true;
    }
    var minRating = items.minRating;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1280, min: 900 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 900, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    if(!isLoaded) {
        return <div> Google Review Loading ... </div>
    }
    else if(isGroup){
      return (
        <div className="container-fluid">
          <Carousel
            additionalTransfrom={0}
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={700}
            containerClass="react-multi-carousel-item"
            arrows={false}
            deviceType={this.props.deviceType}
            dotListClass=""
            itemClass="carousel-item-padding-10-px"
            slidesToSlide={1}
            focusOnSelect={false}
            renderButtonGroupOutside={true}
            renderDotsOutside={true}
          >
            {
              items.mReview.filter(mReview => mReview.rating >= mReview.minRating && mReview.text !== "" && mReview.text.length >15 ).map(item => (
          
            <div key={item.reviewerid} className="gutter-images grid-item">
              <div className="image-wrapper block-link-hover3">
                <div className="border-boxes review-grid-group review-grid-group1">
                  <div className="cursor-pointer">
                   <div className="review-client-logo"><img alt="Image02" className="img-client-logo" src={item.businesslogo}/></div>
                    <div className="review-client-name">{item.businessName}</div>
                    <Link target="_blank" rel="noopener, noreferrer" href={item.share_url} style={{ textDecoration: 'none' }}>
                    <div className="boxlink">
                      <div className="div-img-boxed-1"><img alt="Image01" className="img-boxed" src={item.reviewer_avatar}/></div>
                      <p className="font-w600 reviewer-name">{item.reviewer}</p>
                      <div className="review-date">{item.localized_date}</div>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starDimension={'20px'}
                        starSpacing={'0px'}
                        starRatedColor="#F8B80D"
                        numberOfStars={5}
                        name='customer-rating'/>
                    </div>
                    <div className="review-grid-text reviews-text">
                      <ReadMoreReact text={htmlToText(item.text)}
                        min={300}
                        ideal={400}
                        max={410}
                        readMoreText={"read more"}/>
                        {(item.owner_response !== "" && items.show_OwnerResponse  ) ?  (<div className="image-wrapper owner-response"><ReadMoreReact text={htmlToText(item.owner_response)} min={300} ideal={400} max={410} readMoreText={"read more"}/></div>) : ''} 
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            ))
          }
          </Carousel>
        </div>
      );
    }
    else {
      return (
        <div className="container-fluid">
          {(items.leaveReview) ?  (<div className="review_btn"><a rel="noreferrer" href={"https://search.google.com/local/writereview?placeid="+items.locid} target="_blank">Leave us a Review</a></div>) : ''} 
          <Carousel
            additionalTransfrom={0}
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={700}
            containerClass="react-multi-carousel-item"
            arrows={false}
            deviceType={this.props.deviceType}
            dotListClass=""
            itemClass="carousel-item-padding-10-px"
            slidesToSlide={1}
            focusOnSelect={false}
            renderButtonGroupOutside={true}
            renderDotsOutside={true}
          >
            {
              items.mReview.filter(mReview => mReview.rating >= minRating && mReview.text !== "" && mReview.text.length >15 ).map(item => (
            <div key={item.id} className="gutter-images grid-item">
              <div className="image-wrapper block-link-hover3">
                <div className="border-boxes review-grid-group review-grid-group1">
                  <div className="cursor-pointer">
                    <Link target="_blank" rel="noopener, noreferrer" href={item.share_url} style={{ textDecoration: 'none' }}>
                    <div className="boxlink">
                      <div className="div-img-boxed-1"><img alt="Image01" className="img-boxed" src={item.reviewer_avatar}/></div>
                      <p className="font-w600 reviewer-name">{item.reviewer}</p>
                      <div className="review-date">{item.localized_date}</div>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starDimension={'20px'}
                        starSpacing={'0px'}
                        starRatedColor="#F8B80D"
                        numberOfStars={5}
                        name='customer-rating'/>
                    </div>
                    <div className="review-grid-text reviews-text">
                      <ReadMoreReact text={htmlToText(item.text)}
                        min={300}
                        ideal={400}
                        max={410}
                        readMoreText={"read more"}/>
                        {(item.owner_response !== "" && items.show_OwnerResponse  ) ?  (<div className="image-wrapper owner-response"><ReadMoreReact text={htmlToText(item.owner_response)} min={300} ideal={400} max={410} readMoreText={"read more"}/></div>) : ''} 
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            ))
          }
          </Carousel>
        </div>
      );
    }
  }
}

export default Reviews;
